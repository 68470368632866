import { DomainMember } from '../domain/domain-members.entity';

interface DomainMembersDto {
	domainParticipationsByCode: {
		user: {
			id: number;
			firstName: string;
			lastName: string;
			email: string;
			company: {
				name: string;
			};
			positions: {
				name: string;
			}[];
		};
		roles: string[];
	}[];
	domainParticipationsCountByCode: number;
}

export const mapDomainMembers = ({
	domainParticipationsByCode,
	domainParticipationsCountByCode,
}: DomainMembersDto): {
	data: DomainMember[];
	totalCount: number;
} => ({
	data: domainParticipationsByCode.map(
		({ roles, user: { firstName, lastName, email, company, positions, id } }) => ({
			id,
			firstName,
			lastName,
			email,
			company: company ? company.name : null,
			domainRoles: roles,
			positions: positions.map(({ name }) => name),
		})
	),
	totalCount: domainParticipationsCountByCode,
});
