import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { languages } from '@asd-stan/common/enums';
import { getCurrentUserService } from '@asd-stan/current-user/infrastructure/getters';
import { getDomainService } from '@asd-stan/domain/infrastructure/getters';
import { userHasRoles } from '@asd-stan/helpers/app-utils';
import {
	getStandardPublicService,
	getStandardService,
} from '@asd-stan/standard/infrastructure/getters';
import { FormInput } from '@asd-stan/ui-kit/components/form-input/form-input';
import { FormSelect } from '@asd-stan/ui-kit/components/form-select/form-select';
import { SystemRole } from '@asd-stan/user/domain/system-role.entity';
import { UserCollectionFilter } from '@asd-stan/user/domain/user.service';
import { FormInputParagraph } from '@components/form-input-paragraph/form-input-paragraph';
import { Option } from '@components/form-select/form-select-async';
import {
	AdditionalType,
	FormSelectAsyncPagination,
} from '@components/form-select/form-select-async-pagination';
import { FormTextarea } from '@components/form-textarea/form-texarea';
import { Field, FormikValues, useFormikContext } from 'formik';

import { forms, revisions } from '../../../domain/enums';
import { ProjectLeader } from '../../general/project-leader/project-leader';
import { ReplaceContainer } from '../../general/replace-container/replace-container';

const LIMIT = 6;

interface WorkGroup {
	code: string;
	name: string;
}

export const General: React.FC = observer(() => {
	const url = window.location.href;
	const params = useParams();
	const isNew = !params.id;
	const { t } = useTranslation();
	const currentUserService = getCurrentUserService();
	const domainService = getDomainService();
	const standardService = getStandardService();
	const standardPublicService = getStandardPublicService();
	const { values, setFieldValue, validateField, handleSubmit } = useFormikContext<FormikValues>();
	const regNoReqRef = useRef<null | AbortController>(null);
	const stanNoReqRef = useRef<null | AbortController>(null);
	const formInitiallyChangedRef = useRef(false);

	const isNeedContact = !userHasRoles(
		[SystemRole.ES, SystemRole.DIRECTOR],
		currentUserService.userRoles!
	);

	const regAndStanNoDisabled = !standardService.checkIfCurrentUserCanEditRegAndStanNo(
		values.domainCodes?.map(({ value }: { value: string }) => value) ?? []
	);

	const calculateOffset = (page: number) => {
		return (page - 1) * LIMIT;
	};

	useEffect(() => {
		const onLoadPage = async () => {
			domainService.getDomains();
			standardService.getAllTypes();
		};

		onLoadPage();
	}, [domainService, standardService]);

	const domainOptions = domainService.domains.map(domain => {
		return { label: `${domain.code} ${domain.name}`, value: domain.code };
	});

	const typeOptions = standardService.allTypes.map(type => {
		return { label: type.name, value: type.id };
	});

	const createOptionsFromDomainWorkGroups = (
		domainCode: any[]
	): { label: string; value: string }[] => {
		if (!domainCode) {
			return [];
		}

		const options = domainCode.flatMap((element: { value: string }) => {
			const workGroups: WorkGroup[] | undefined = domainService.getDomainWorkingGroups(
				element.value
			);

			return (
				workGroups?.map((workGroup: WorkGroup) => ({
					label: workGroup.code === 'None' ? workGroup.name : `${workGroup.code} ${workGroup.name}`,
					value: workGroup.code,
				})) || []
			);
		});

		!(values.domainCodes.length === 0) && options.push({ label: 'None', value: 'None' });
		return options.sort((a, b) => a.value.localeCompare(b.value));
	};

	const handleLoadLatestStandards = async (
		search: string,
		_prevOptions: any,
		{ page }: AdditionalType
	) => {
		const offset = calculateOffset(page);

		const count = await standardService.getLatestStandardsList(LIMIT, offset, {
			childSearch: true,
			registrationNumber: search,
			isDraft: false,
		});

		const updateStandards = standardService.latestStandards.map(standard => {
			return {
				label: `${standard.registrationNumber ?? ''} ${standard.form ?? ''} ${
					standard.localizedTitle ?? ''
				}`,
				value: standard,
			};
		});
		const hasMore = page < Math.ceil(count / 6);

		return {
			options: updateStandards.filter(
				standard =>
					standard.value.id !== standardService.singleStandard?.id
			),
			hasMore,
			additional: {
				page: page + 1,
			},
		};
	};

	const handleLoadUsersStandards = async (
		search: string,
		_prevOptions: any,
		{ page }: AdditionalType
	) => {
		const filter: Partial<UserCollectionFilter> = values.domainCodes.reduce(
			(filter: { domainCodes: Array<string> }, code: { label: string; value: string }) => {
				if (!filter.domainCodes) {
					filter.domainCodes = [];
				}
				filter.domainCodes.push(code.value);

				return filter;
			},
			{
				search: !!search ? search : undefined,
			} as Partial<UserCollectionFilter>
		);

		const offset = calculateOffset(page);
		const userList = await standardPublicService.getUsersByDomains(filter, LIMIT, offset);

		const users = userList.data.map(user => {
			return {
				label: <ProjectLeader user={user} />,
				value: user.id,
			};
		});
		const hasMore = page < Math.ceil(userList.totalNumber / LIMIT);

		return {
			options: users,
			hasMore,
			additional: {
				page: page + 1,
			},
		};
	};

	const handleChangeRevision = async (name: string, option: Option) => {
		await setFieldValue(name, option);
		handleSubmit();
		await setFieldValue('revisionJustification', '');
		await setFieldValue('technicalChanges', [{ title: '', change: '' }]);
		if (!option) {
			return;
		}

		if (option.value === 'no') {
			if (!values.edition) {
				await setFieldValue('edition', 'P1');
			}
			await setFieldValue('showReplaceStandard', false);
			await setFieldValue('standard', '');
			await setFieldValue('technicalChanges', []);
			handleSubmit();
			return validateField('edition');
		}
	};

	const handleFormChange = async (name: string, option: Option) => {
		setFieldValue(name, option);
		if (!formInitiallyChangedRef.current) {
			formInitiallyChangedRef.current = true;
			getRegNoValue(option.value);
			getStanNoValue(option.value);
		}
		if (!option) {
			setFieldValue('revisionId', { label: '', value: '' });
			setFieldValue('standard', '');
			setFieldValue('revisionJustification', '');
			setFieldValue('technicalChanges', []);
		}
	};

	const getRegNoValue = async (form: string) => {
		const abortController = new AbortController();
		regNoReqRef.current = abortController;
		const regNumber = await standardService.getNextRegNumber(form);
		if (regNumber !== undefined && !abortController.signal.aborted) {
			setFieldValue('registrationNumber', regNumber);
		}
	};

	const handleRegNoChange = async (value: string) => {
		regNoReqRef.current?.abort();
		if (!values.form || !isNew || value !== '') {
			return;
		}
		getRegNoValue(values.form.value);
	};

	const getStanNoValue = async (form: string) => {
		const abortController = new AbortController();
		stanNoReqRef.current = abortController;
		const stanNumber = await standardService.getNextStanNumber(form);
		if (stanNumber !== undefined && !abortController.signal.aborted) {
			setFieldValue('stanNumber', stanNumber);
		}
	};

	const handleStanNoChange = async (value: string) => {
		stanNoReqRef.current?.abort();
		if (!values.form || !isNew || value !== '') {
			return;
		}
		getStanNoValue(values.form.value);
	};

	useEffect(() => {
		setFieldValue('showReplaceStandard', false);
		if (!values.revisionId) {
			setFieldValue('standard', '');
		}
	}, [setFieldValue, values.revisionId]);

	const handleChangeLatestStandards = async (name: string, option: Option) => {
		await setFieldValue(name, option);
		await setFieldValue('showReplaceStandard', option === null ? false : true);
		handleSubmit();
		validateField(name);
	};

	const handleWGChange = async (name: string, option: Option | Option[]) => {
		if (Array.isArray(option)) {
			if (option[option.length - 1]?.value === 'None') {
				await setFieldValue(name, option[option.length - 1]);
			} else {
				await setFieldValue(name, option);
			}
		} else {
			if (option === null) {
				return await setFieldValue(name, []);
			}
			if (option.value !== 'None') {
				await setFieldValue(name, [option]);
			}
		}
		handleSubmit();
	};

	return (
		<>
			<Field
				component={FormSelect}
				name="form"
				title={t('standard.createNWP.general.formTitle')}
				options={forms}
				onChange={handleFormChange}
				mandatory={!standardService.singleStandard?.isMigrated}
				showError
				useSubmitOnChange
			/>
			<div style={{ width: '49%' }}>
				<Field
					component={FormInput}
					name="registrationNumber"
					title={t('standard.createNWP.general.regTitle')}
					mandatory={!standardService.singleStandard?.isMigrated}
					disabled={(!values.form && !values.registrationNumber) || regAndStanNoDisabled}
					showCheckbox
					fullWidth
					showError
					regExp={standardService.singleStandard?.isMigrated ? undefined : /[^\d.-]/gi}
					useSubmitOnBlur
					onCustomChange={handleRegNoChange}
				/>
			</div>
			<div style={{ width: '49%' }}>
				<Field
					component={FormInput}
					name="stanNumber"
					title={t('standard.createNWP.general.stanTitle')}
					mandatory={!standardService.singleStandard?.isMigrated}
					disabled={(!values.form && !values.stanNumber) || regAndStanNoDisabled}
					showCheckbox
					fullWidth
					showError
					regExp={standardService.singleStandard?.isMigrated ? undefined : /[^\d.-]/gi}
					useSubmitOnBlur
					onCustomChange={handleStanNoChange}
				/>
			</div>
			<Field
				component={FormSelect}
				name="revisionId"
				title={t('standard.createNWP.general.revisionTitle')}
				options={revisions}
				onChange={handleChangeRevision}
				useSubmitOnChange
				mandatory={!standardService.singleStandard?.isMigrated}
				disabled={!values.form}
				showError
			/>
			<Field
				component={FormSelectAsyncPagination}
				name="standard"
				title={t('standard.createNWP.general.standardTitle')}
				mandatory={
					standardService.singleStandard?.isMigrated
						? false
						: values.revisionId &&
						  values.revisionId.value === 'yes' &&
						  !userHasRoles([SystemRole.EXPERT], currentUserService.userRoles!)
				}
				onChange={handleChangeLatestStandards}
				defaultOptions
				loadOptions={handleLoadLatestStandards}
				disabled={
					!values.revisionId ||
					values.revisionId.value !== 'yes' ||
					userHasRoles([SystemRole.EXPERT], currentUserService.userRoles!)
				}
				fullWidth
				showError
			/>
			{values.showReplaceStandard && values.revisionId && values.revisionId.value === 'yes' && (
				<ReplaceContainer standard={values.standard} />
			)}
			<Field
				component={FormInput}
				name="edition"
				title={t('standard.createNWP.general.editionTitle')}
				mandatory={!standardService.singleStandard?.isMigrated}
				showError
				useSubmitOnBlur
			/>
			<Field
				component={FormSelect}
				name="languages"
				title={t('standard.createNWP.general.languagesTitle')}
				options={languages}
				useSubmitOnChange
				mandatory={!standardService.singleStandard?.isMigrated}
				isMulti
				showError
			/>
			<Field
				component={FormSelect}
				name="typeIds"
				title={t('standard.createNWP.general.typeTitle')}
				options={typeOptions}
				showError
				useSubmitOnChange
				isMulti
				fullWidth
			/>
			<Field
				component={FormInput}
				name="pages"
				title={t('standard.createNWP.general.pagesTitle')}
				showError
				onlyDigits
				maxLength={9}
				disableMaxLength={url.includes('draft')}
				useSubmitOnBlur
			/>
			<Field
				disabled={!currentUserService.hasRole([SystemRole.DIRECTOR, SystemRole.ES])}
				component={FormInput}
				name="cenWiNumber"
				title={t('standard.createNWP.general.cenWiNumber')}
				showError
				useSubmitOnBlur
			/>
			<Field
				component={FormSelect}
				name="domainCodes"
				title={t('standard.createNWP.general.domainTitle')}
				options={domainOptions}
				mandatory={!isNeedContact && !standardService.singleStandard?.isMigrated}
				fullWidth
				isMulti
				showError
				useSubmitOnChange
				customChange={() => {
					setFieldValue('leaderId', null);
					setFieldValue('experts', []);
					setFieldValue('wgs', null);
				}}
			/>
			<Field
				component={FormSelect}
				name="workingGroupCodes"
				title={t('standard.createNWP.general.workingGroupTitle')}
				options={createOptionsFromDomainWorkGroups(values.domainCodes)}
				mandatory={!isNeedContact && !standardService.singleStandard?.isMigrated}
				onChange={handleWGChange}
				useSubmitOnChange
				fullWidth
				disabled={
					values.domainCodes && values.domainCodes[0] && values.domainCodes[0].value !== ''
						? false
						: true
				}
				isMulti={
					values.domainCodes !== undefined && values.workingGroupCodes !== undefined
						? values.workingGroupCodes?.value !== 'None'
						: false
				}
				showError
			/>
			<Field
				component={FormSelectAsyncPagination}
				name="leaderId"
				title={t('standard.createNWP.general.projectLeaderTitle')}
				defaultOptions
				loadOptions={handleLoadUsersStandards}
				disabled={
					values.domainCodes && values.domainCodes[0] && values.domainCodes[0].value !== ''
						? false
						: true
				}
				fullWidth
				showError
				useSubmitOnChange
				key={JSON.stringify(values.domainCodes)}
			/>
			<Field
				component={FormTextarea}
				name="standardTitleEN"
				title={t('standard.createNWP.titles.standardTitleEN')}
				placeholder={t('standard.createNWP.titles.standardTitleENPlaceholder')}
				fullWidth
				mandatory={!standardService.singleStandard?.isMigrated}
				showError
				maxLength={1000}
				useSubmitOnBlur
				disableMaxLength={url.includes('draft')}
			/>
			<Field
				component={FormTextarea}
				name="standardTitleDE"
				title={t('standard.createNWP.titles.standardTitleDE')}
				placeholder={t('standard.createNWP.titles.standardTitleDEPlaceholder')}
				fullWidth
				maxLength={1000}
				useSubmitOnBlur
				disableMaxLength={url.includes('draft')}
			/>
			<Field
				component={FormTextarea}
				name="standardTitleFR"
				title={t('standard.createNWP.titles.standardTitleFR')}
				placeholder={t('standard.createNWP.titles.standardTitleFRPlaceholder')}
				fullWidth
				maxLength={1000}
				useSubmitOnBlur
				disableMaxLength={url.includes('draft')}
			/>
			<Field
				component={FormInputParagraph}
				name="scope"
				title={t('standard.createNWP.general.scopeTitle')}
				mandatory={!standardService.singleStandard?.isMigrated}
				fullWidth
				maxLength={5000}
				showError
				useSubmitOnBlur
				disableMaxLength={url.includes('draft')}
			/>
		</>
	);
});
